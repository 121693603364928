import React from 'react';
import {Button, Select, Switch} from 'antd';
import {ReactComponent as Print} from '../assets/icons/Print.svg';
import {ReactComponent as Marker} from '../assets/icons/Marker.svg';

function PrintOptions({title = 'Print Driver Duty', printLayout, setPrintLayout, printMode, setPrintMode, showMap, setShowMap}) {
    return (
        <>
            <div className="ant-modal-header d-flex justify-content-between hide-print"
                 style={{paddingRight: '28px', marginBottom: '25px'}}>
                <div className="ant-modal-title">{title}</div>
                <div className="d-flex align-items-center" style={{gap: '20px'}}>
                    <Select
                        onSelect={setPrintLayout}
                        value={printLayout}
                        options={[
                            {value: 'lone', label: 'Layout 1'},
                            {value: 'ltwo', label: 'Layout 2'},
                        ]}
                    />
                    <Select
                        onSelect={setPrintMode}
                        value={printMode}
                        options={[
                            {value: 'lg', label: 'Comfortable'},
                            {value: 'md', label: 'Standard'},
                            {value: 'sm', label: 'Compact'},
                        ]}
                    />
                    <Switch size="small" onChange={setShowMap} checked={showMap} style={{display: 'none'}}/>
                    <Button onClick={() => setShowMap(!showMap)}
                            className={`info-icon-button light ${showMap ? 'active' : ''}`}><Marker/> {showMap ? `Hide Map` : `Show Map`}
                    </Button>
                    <Button key="submit" type="primary" className="btn-primary icon-btn" icon={<Print/>}
                            onClick={() => window.print()}>
                        Print
                    </Button>
                </div>
            </div>
        </>
    );
}

export default React.memo(PrintOptions);
