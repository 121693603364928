import React, {useCallback, useEffect, useState} from "react";
//import {BsPencilSquare} from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import {useAppContext} from "../libs/contextLib";
import {onError} from "../libs/errorLib";
import {ButtonGroup, Col, FormControl, InputGroup} from "react-bootstrap";
import {debounce, values} from "lodash";
import {filterFn, FilterSwitches} from "../containers/FilterTag";
import EditableListItem from "./EditableListItem";
import LoadMessage from "../components/LoadMessage";
import {Button} from "antd";
import {PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {ReactComponent as Import} from "../assets/icons/Import.svg";
import UploadModal from "../components/UploadModal";

const findKey = (item, keyEnd) => Object.keys(item).filter(key => key.endsWith(keyEnd))[0]
const findNameFn = item => item[findKey(item, 'Name')]

export const DEFAULT_EDITABLE_PROPS = {
    defaultItems: [],
    itemNameFn: findNameFn,
    itemIdFn: (item) => item[findKey(item, 'Id')],
    itemIdKey: (item) => findKey(item, 'Id'),
    sortFn: (a, b) => {
        const aName = findNameFn(a), bName = findNameFn(b);
        if (!aName?.length || !bName?.length) {
            return 1
        }
        aName.localeCompare(bName);
    },
    // itemFilterFn: (item, lowerCaseFilter) => true,
    isEditable: () => true,
    isCopyable: () => true,
    isDeletable: () => true,
    isReplicable: () => false,
    isExportable: () => false,
    isImportable: () => false,
}

function EditableList(props) {
    const {operator, setBreadcrumb} = useAppContext();
    const [sortedItems, setSortedItems] = useState(null);

    const [isDeleting, setIsDeleting] = useState([]);
    const [newItemVisible, setNewItemVisible] = useState(false);
    const [viewing, setViewing] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [filter, setFilter] = useState(null)
    const setFilterDebounced = debounce(setFilter, 250);
    const [isReplicating, setIsReplicating] = useState([]);
    const [isCopying, setIsCopying] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [importModalVisible, setImportModalVisible] = useState(false)
    const {
        defaultItems,
        itemNameFn,
        itemIdFn,
        sortFn,
        filterItems,
        itemFilterFn,
        items,
        modelData,
        setItems,
        modelCopyFn,
        isImportable,
        isExportable,
        replicateFn,
        uploadProps,
        handleUpload,
        uploading,
        createItemFn,
        headerCols,
        editableView,
        fetch = true
    } = {...DEFAULT_EDITABLE_PROPS, ...props}


    useEffect(() => {
        if (!fetch) {
            return
        }
        modelData.addListener({
            id: props.itemName + 'sModelListener',
            setterFn: setItems,
            loaded: items => setItems(items)
        })
        return () => {
            modelData.removeListener(props.itemName + 'sModelListener')
        }
    }, [modelData, setItems, props.itemName, fetch])

    useEffect(() => {
        if (viewing?.length) {
            if (viewing.length === 1) {
                const item = items[viewing[0]]
                if (item) {
                    setBreadcrumb(findNameFn(item));
                } else {
                    setBreadcrumb(null);
                }
            } else if (viewing.length > 1) {
                setBreadcrumb(`${viewing.length} ${props.itemName}s selected`)
            } else {
                setBreadcrumb(null);
            }
            return
        }
        setBreadcrumb(null);
        const filterableItems = defaultItems.concat(values(items))
        console.log('We have %d items', filterableItems.length)

        if (!filter?.length || !itemFilterFn) {
            return setSortedItems(filterFn(filterableItems, filterItems, filterBy, itemIdFn, sortFn))
        }

        const _filter = filter.toLowerCase()
        setSortedItems(filterFn(filterableItems.filter(item => itemFilterFn(item, _filter)), filterItems, filterBy, itemIdFn, sortFn));
    }, [defaultItems, items, setSortedItems, filterBy, viewing, filterItems, itemIdFn, sortFn, itemFilterFn, setBreadcrumb, props.itemName, filter])

    // eslint-disable-next-line
    async function handleDelete(event, item) {
        event.preventDefault();

        const confirmed = window.confirm(
            `Are you sure you want to delete ${itemNameFn(item)}?`
        );

        if (!confirmed) {
            return;
        }

        setIsDeleting(isDel => isDel.concat(itemIdFn(item)));

        try {
            await modelData.delete(itemIdFn(item), true)
        } catch (e) {
            onError(e);
        }
        setIsDeleting(isDel => isDel.filter(id => id !== itemIdFn(item)));
    }

    const handleCopy = async (event, item) => {
        event.preventDefault();

        if (!window.confirm(`Are you sure you want to copy ${itemNameFn(item)}?`)) {
            return;
        }

        setIsCopying(is => is.concat(itemIdFn(item)));

        try {
            await modelData.copy({...item, ...modelCopyFn(item)})
        } catch (e) {
            onError(e);
        }
        setIsCopying(is => is.filter(id => id !== itemIdFn(item)));
    }

    const handleReplicate = useCallback(async (item) => {
        if (!replicateFn) {
            return
        }
        if (!window.confirm(`Are you sure you want to copy ${itemNameFn(item)} to all of your regions?`)) {
            return;
        }

        setIsReplicating(ifo => ifo.concat(itemIdFn(item)));
        try {
            await replicateFn(item)
        } catch (e) {
            console.log(`Couldn't forward item. Error: ${e}`)
        }
        setIsReplicating(ifo => ifo.filter(id => id !== itemIdFn(item)));
    }, [setIsReplicating, replicateFn, itemIdFn, itemNameFn])

    const handleItemUpdate = useCallback((item) => {
        setItems(items => {
            items[itemIdFn(item)] = createItemFn(item);
            return {...items};
        })
        setViewing(viewing => viewing.filter(sId => sId !== itemIdFn(item)))
        setNewItemVisible(false)
    }, [setItems, itemIdFn, createItemFn, setViewing, setNewItemVisible])

    const handleCancel = useCallback((item) => {
        setViewing(viewing => viewing.filter(sId => sId !== itemIdFn(item)))
        setNewItemVisible(false)
    }, [setViewing, itemIdFn, setNewItemVisible])

    function renderSortedItems(sortedItems) {
        return (
            <>
                {newItemVisible ?
                    <ListGroup.Item key={'new_item_row'} className="mb-5">
                        {editableView({
                            handleItemUpdate,
                            handleCancel, ...DEFAULT_EDITABLE_PROPS, ...props,
                            itemIdFn: () => '_'
                        })}
                    </ListGroup.Item> :
                    <></>
                }
                <div className="row list-group-header">
                    {headerCols}
                    {props.noTags ? <></> : <Col lg={1} className="text-center">{props.tagHdr || 'Tags'}</Col>}
                    <Col lg={2} className="text-center">Actions</Col>
                </div>
                {!isLoading ? sortedItems.map(item => {
                        return (
                            <EditableListItem
                                editableView={editableView({item, ...props})}
                                key={`li-${itemIdFn(item)}`}
                                item={item}
                                handleCancel={handleCancel}
                                handleItemUpdate={handleItemUpdate}
                                handleDelete={handleDelete}
                                isDeleting={isDeleting}
                                handleReplicate={handleReplicate}
                                isReplicating={isReplicating}
                                handleCopy={handleCopy}
                                isCopying={isCopying}
                                viewing={viewing}
                                setViewing={setViewing}
                                {...props}
                            />
                        )
                    }) :
                    <LoadMessage message={`loading ${props.itemName ? props.itemName.toLowerCase() + 's' : ''}...`}
                                 size={"lg"}/>
                }
            </>
        );
    }

    function renderList(sortedItems) {
        return (
            <>
                <UploadModal visible={importModalVisible} setVisible={setImportModalVisible} props={uploadProps}
                             uploading={uploading}/>
                <div className="EditableList">
                    <div className="d-flex align-items-center justify-content-between filter-options-main">
                        <ButtonGroup style={{gap: '10px'}}>
                            <Button type="primary" className="icon-button" icon={<PlusOutlined/>}
                                    onClick={() => setNewItemVisible(true)}>{props.itemName}</Button>
                            {/*{isExportable && <Button type="primary" className="icon-button" icon={<Export/>}*/}
                            {/*                        onClick={() => exportFn()}>Export</Button>}*/}
                            {isImportable && uploadProps &&
                                <Button type="primary" className="icon-button" icon={<Import/>}
                                        onClick={() => setImportModalVisible(true)}>Import</Button>}
                        </ButtonGroup>
                        <div className="d-flex align-items-center pl-3">
                            <div className="justify-content-end w-separator">
                                <InputGroup className="search-filter">
                                    <FormControl
                                        type="search"
                                        placeholder="Filter"
                                        className=""
                                        size="sm"
                                        aria-label="Filter"
                                        onChange={(e) => setFilterDebounced(e.target.value || "")}
                                    />
                                    <InputGroup.Text id="basic-addon1"><SearchOutlined/></InputGroup.Text>
                                </InputGroup>
                            </div>
                            <div className="filter-switches">
                                <FilterSwitches filterItems={filterItems} filterBy={filterBy}
                                                setFilterBy={setFilterBy}/>
                            </div>
                        </div>
                    </div>
                    <ListGroup>{sortedItems && operator?.features?.access && renderSortedItems(sortedItems)}</ListGroup>
                </div>
            </>
        );
    }

    return (
        renderList(sortedItems)
    );
}

export default React.memo(EditableList);

