import React, {useEffect, useMemo, useState} from 'react';
import LoaderButton from '../../components/LoaderButton';
import {ReactComponent as Copy} from '../../assets/icons/Copy.svg';
import {ReactComponent as Trash} from '../../assets/icons/Trash.svg';
import {Charter} from '../../model/charter';
import { useHistory} from 'react-router-dom';
import {charterModelData} from '../../services/ModelService';
import {FilterTags} from '../../containers/FilterTag';
import {filterItems} from './Charters';
import {Popconfirm} from 'antd/lib';
import {ulid} from 'ulid';
import {Button, Flex, Table} from 'antd';
import CharterTimesPopover from '../../components/CharterTimesPopover';
import {useAppContext} from '../../libs/contextLib';

const CharterList = ({charters}) => {
    const {schedules: allSchedules} = useAppContext();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [isDeleting, setIsDeleting] = useState({});

    const columns = useMemo(() => {
        return [
            {title: 'Name', dataIndex: 'name', key: 'name', sorter: (a, b) => a.name?.localeCompare ? a.name.localeCompare(b.name) : -1},
            {
                title: 'Calendars', dataIndex: 'calendars', key: 'calendars', width: 300,
                render: (_, charter) => <CharterTimesPopover charter={charter} schedules={allSchedules}/>
            },
            {
                title: 'Status', dataIndex: 'status', key: 'status', width: 100,
                sorter: (a, b) => a.status?.localeCompare ? a.status?.localeCompare(b.status) : 1,
                render: (_, charter) => <div className="d-flex justify-content-center simple-tags">
                    <FilterTags filterItems={filterItems} model={charter}/>
                </div>
            },
            {
                title: 'Actions', dataIndex: 'actions', key: 'actions', width: 100,
                render: (_, charter) => (
                    <Flex justify={'space-around'}>
                        <LoaderButton
                            className="btn-icon-control"
                            size="sm"
                            // isLoading={isCloning}
                            onClick={() => {
                                console.log('Copy charter', charter.charterId);
                                charter = charter.clone();
                                charter.charterId = ulid();
                                charter.name += '-COPY';
                                charterModelData.save(charter).then(() => {
                                    console.log('Charter saved.');
                                });
                            }}
                        >
                            <Copy/>
                        </LoaderButton>
                        <Popconfirm title={`Are you sure you want to delete ${charter.name}?`}
                                    onClick={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onCancel={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onConfirm={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setIsDeleting({...isDeleting, [charter.charterId]: true});
                                        console.log('Delete charter', charter.charterId);
                                        charterModelData.delete([charter.charterId, charter.itineraryId], true).then(() => {
                                            console.log('Charter deleted.');
                                            setIsDeleting({...isDeleting, [charter.charterId]: false});
                                        });
                                    }}> <Button
                            className="btn-icon-control btn-delete"
                            size="sm"
                            loading={isDeleting[charter.charterId]}
                            icon={<Trash/>}/>
                        </Popconfirm>
                    </Flex>
                )
            },
        ];
    }, [allSchedules]);

    useEffect(() => {
        if (charters && setData) {
            setData(
                charters.map((charter) => {
                    return new Charter({
                        ...charter,
                        key: charter.charterId,
                        // warnings: charter.validateCharter(charters),
                    });
                })
            );
        }
    }, [charters, setData]);


    return <Table size={'middle'} key={'charter-list-table'} columns={columns} dataSource={data} pagination={false} virtual={true}
                  onRow={(charter) => {
                      return {
                          onClick: () => history.push(`/charters/${charter.charterId}`),
                      };
                  }}
                  rowClassName={() => 'charter-row'}
                  scroll={{y: 600}}
    />;

};

export default React.memo(CharterList);
