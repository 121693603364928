import React from 'react';
import {Modal} from 'antd';
import WorkDiaryPrintView from './WorkDiaryPrintView';

function WorkDiaryPrintModal({
                                 workDiaries,
                                 allStops, allRoutes,
                                 visible,
                                 schedules,
                                 setVisible
                             }) {
    return (
        <Modal
            width={1000}
            style={{top: 50}}
            open={visible}
            destroyOnClose
            okText={`Print`}
            onCancel={() => setVisible(null)}
            onOk={() => window.print()}
        >
            <WorkDiaryPrintView
                allRoutes={allRoutes}
                allStops={allStops}
                workDiaries={workDiaries}
                schedules={schedules}/>
        </Modal>
    );
}

export default React.memo(WorkDiaryPrintModal);
