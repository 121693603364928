import React, {useState} from "react";
import {isSameTimeExcludingSeconds, toTime} from "../../libs/formatLib";
import Pin from "../../components/Pin";
import "./ShiftBat.css"
import {ulid} from "ulid";
import ShiftBatStopNoteEdit from "./ShiftBatStopNoteEdit";
import ShiftBatNoteRow from "./ShiftBatNoteRow";
import ShiftBatTransferRowPrint from "./ShiftBatTransferRowPrint";
import {useAppContext} from "../../libs/contextLib";

function ShiftBatStopRowPrint({
                                  route,
                                  shiftBatStopRow,
                                  onSave,
                                  editMode,
                                  printMode,
                                  setSelectedStop,
                                  selectedStop,
                                  setFocusStop
                              }) {

    const {operator} = useAppContext();
    const [editing, setEditing] = useState({})

    return (<>
        <div
            className={`row align-items-center align-items-stretch justify-content-center ${selectedStop && shiftBatStopRow?.stop.stopTimeId === selectedStop.stopTimeId ? 'Selected' : ''}`}
            style={{cursor: 'pointer'}}
            onClick={e => {
                if (printMode) {
                    return
                }
                e.stopPropagation();
                setSelectedStop(shiftBatStopRow.stop)
            }}
            onMouseOver={() => {
                if (printMode) {
                    return
                }
                setFocusStop(shiftBatStopRow.stop);
            }}>
            <div className="col-1 sb-time">
                {operator.opts?.shiftbat?.arrivalAndDeparture &&
                shiftBatStopRow.time > -1 &&
                !isSameTimeExcludingSeconds(shiftBatStopRow.stop?.arriveSecs, shiftBatStopRow.time) ? (
                    <span>{`${toTime(shiftBatStopRow.stop?.arriveSecs)} ${toTime(shiftBatStopRow.time)}`}</span>
                ) : (
                    shiftBatStopRow.time > -1 && (
                        <span>{toTime(shiftBatStopRow.time)}</span>
                    )
                )}
            </div>
            <div className="col-2 sb-step-no">{shiftBatStopRow.stop &&
                <Pin size={20} type={shiftBatStopRow.stop.type}
                     sequence={shiftBatStopRow.sequence}/>}</div>
            <div className="col-9 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                <div className={`RouteInfo`}>
                    <div className="row align-items-stretch justify-content-center">
                        <div className="col-5 d-flex align-items-center flex-wrap">
                            <h4 className="title">{shiftBatStopRow.title}</h4>
                        </div>
                        <div className="col-7 d-flex align-items-center flex-wrap">
                            {shiftBatStopRow.transfersTo?.length ?
                                <ShiftBatTransferRowPrint shiftBatStopRow={shiftBatStopRow}
                                                          transfers={shiftBatStopRow.transfersTo}
                                                          pickup={false}/> : <></>
                            }
                            {shiftBatStopRow.transfersFrom?.length ?
                                <ShiftBatTransferRowPrint shiftBatStopRow={shiftBatStopRow}
                                                          transfers={shiftBatStopRow.transfersFrom}
                                                          pickup={true}/> : <></>
                            }
                            {shiftBatStopRow.noteRows && shiftBatStopRow.noteRows.map((shiftBatNote, idx) => (
                                editing[shiftBatNote.id] ?
                                    <ShiftBatStopNoteEdit key={'ESTR-' + shiftBatNote.id} shiftBatNote={shiftBatNote}
                                                          onCancel={(e, note) => {
                                                              if (!note.title?.length) {
                                                                  shiftBatStopRow.removeNote(note)
                                                                  return onSave(e);
                                                              }
                                                              setEditing(editing => ({
                                                                  ...editing,
                                                                  [shiftBatNote.id]: false
                                                              }))
                                                          }}
                                                          onSave={(e, note) => {
                                                              if (!note.title?.length) {
                                                                  shiftBatStopRow.removeNote(note)
                                                                  return onSave(e);
                                                              }
                                                              shiftBatStopRow.replaceNote(note);
                                                              setEditing(editing => ({
                                                                  ...editing,
                                                                  [shiftBatNote.id]: false
                                                              }))
                                                              onSave(e)
                                                          }}/>
                                    :
                                    <ShiftBatNoteRow key={'STNR-' + shiftBatNote.id}
                                                     shiftBatNoteRow={shiftBatNote} editMode={editMode}
                                                     onEdit={e => setEditing(editing => ({
                                                         ...editing,
                                                         [shiftBatNote.id]: true
                                                     }))}
                                                     onAdd={e => {
                                                         const id = ulid();
                                                         shiftBatStopRow.addNote({id}, idx + 1)
                                                         setEditing(editing => ({...editing, [id]: true}))
                                                     }}
                                                     onDelete={e => {
                                                         shiftBatStopRow.removeNote(shiftBatNote)
                                                         onSave(e);
                                                     }}
                                    />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default React.memo(ShiftBatStopRowPrint)
