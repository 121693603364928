import React, {useState, useEffect} from "react";
import "./Transfers.css";
import {Button} from "antd";
import LoadMessage from "../components/LoadMessage";
import config from "../config";
import {keyBy, values} from "lodash";
import {getKey} from "../libs/hooksLib";
import {ReactComponent as School} from '../assets/icons/School_l.svg';
import ReactGA from "react-ga4";
import {capitalize} from "lodash/string";
import {AppstoreOutlined, BarsOutlined} from "@ant-design/icons";
import {API} from "aws-amplify";
import {Stop} from "../model/busRoute";

function StopTimetablesList({operatorId, stopType = 'school', relPath, view = true}) {
    const [key, setKey] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [allStops, setAllStops] = useState(null);
    const [url, setUrl] = useState("");
    const [toggleView, setToggleView] = useState(view);

    useEffect(() => {
        async function onLoad() {
            const key = operatorId || await getKey();
            setKey(key)

            let stops = await API.get('routes', `/stops?_k=${key}&includingProps=stopId,stopName,stopType,verified`);
            stops = stops.filter(s => s.verified === 1).map(s => new Stop(s))
            setAllStops(keyBy(stops, 'stopId'));
            setIsLoading(false);

            // await routesModelExpiryService.init({apiKey: key, modelFilter: ['Stop']});
            // stopModelData.addListener({
            //     loaded: stops => {
            //         setAllStops(stops)
            //         setIsLoading(false);
            //     }
            // });

            if(relPath) {
                setUrl(relPath)
            } else {
                setUrl(`${config.app.BASE_URL}/timetables/${key}`)
            }
        }

        setIsLoading(true)

        onLoad().then(() => {
            console.log("Stops finished loading.")
            ReactGA.send({ hitType: "pageview", page: "/", title: `publishable-${stopType}-services` });
        }).catch(console.log)

    }, []);

    function renderStopList(stops) {
        if (!stops?.length) {
            return <span>No {stopType} stops.</span>
        }

        return (
            <>
                {stops.sort((s1, s2) => s1.stopName.localeCompare(s2.stopName)).map(stop => (
                    <li key={stop.stopId}>
                        <a href={`${url}?s=${stop.stopId}&st=${stopType}&t=true`}
                           target='_blank' rel="noreferrer" ><School /> {stop.stopName}</a>
                    </li>
                ))}
            </>);
    }

    return (
        <div className={`SchoolStopList ${toggleView ? "SchoolViewList" : "SchoolViewGrid"}`}>
            <div className="d-flex align-items-center justify-content-end filter-options-main top-float">
                {toggleView ? (
                    <Button type="primary" className="icon-button" icon={<AppstoreOutlined />} onClick={() => setToggleView(!toggleView)}> Grid</Button>
                ) : (
                    <Button type="primary" className="icon-button" icon={<BarsOutlined />} onClick={() => setToggleView(!toggleView)}> List</Button>
                )}
            </div>
            {isLoading ?
                <LoadMessage message={`Loading ${capitalize(stopType)}s...`} size={"lg"}/>
                :
                <ul>{renderStopList(values(allStops).filter(s => s?.stopType === stopType))}</ul>
            }
        </div>
    );
}

export default React.memo(StopTimetablesList);

