import {filter} from 'lodash/collection';
import dayjs from '../dayjs';
import {chain, round, sumBy} from 'lodash';

export const DEFAULT_BASE_HOURLY_RATE = 35;
export const DEFAULT_MAX_DAILY_HOURS = 6;
export const DEFAULT_MAX_WEEKLY_HOURS = 38;
export const DEFAULT_OVERTIME_RATE = 1.5;

class EnterpriseAgreement {
    constructor(data) {
        this.baseHourlyRate = null; // Base pay per hour
        this.overtimeMultiplier = null;   // Overtime rate multiplier
        this.maxDailyHours = null;   // Maximum allowed hours per day
        this.maxWeeklyHours = null; // Maximum allowed hours per week
        this.workItems = []; // The work items performed by the driver
        Object.assign(this, data);
    }

    // Calculate weekly pay based on the logged work and overtime
    calculateWeeklyPay(weekStartDate) {
        const weekStart = dayjs(weekStartDate);
        const weekEnd = weekStart.add(6, 'day');

        // Filter logs for the given week
        const weeklyWorkItems = filter(this.workItems, wi => {
            const logDate = wi.date;
            return logDate.isAfter(weekStart.subtract(1, 'day')) && logDate.isBefore(weekEnd.add(1, 'day'));
        });

        // Calculate total hours worked
        const totalHoursWorked = sumBy(weeklyWorkItems, wi => wi.getDuration() / 3600);

        let totalPay = 0;
        let overtimeHours = 0;

        // Check if weekly hours exceed the limit
        if (totalHoursWorked > this.maxWeeklyHours) {
            overtimeHours = totalHoursWorked - this.maxWeeklyHours;
        }

        // Calculate normal pay
        const normalHours = totalHoursWorked - overtimeHours;
        totalPay += normalHours * this.baseHourlyRate;

        // Calculate overtime pay (1.5x rate)
        totalPay += overtimeHours * this.baseHourlyRate * this.overtimeMultiplier;

        return {
            totalHoursWorked,
            overtimeHours,
            totalPay: round(totalPay, 2)
        };
    }

    // Validate compliance with the agreement (check if hours exceed limits)
    validateCompliance() {
        const nonCompliantDays = filter(this.workItems, wi => wi.getDuration() / 3600 > this.maxDailyHours);
        const totalWeeklyHours = chain(this.workItems)
            .groupBy(wi => wi.date.week())
            .map((wi, week) => ({
                week,
                totalHours: sumBy(wi, wi => wi.getDuration() / 3600)
            }))
            .filter(week => week.totalHours > this.maxWeeklyHours)
            .value();

        return {
            nonCompliantDays,
            totalWeeklyHours
        };
    }

    // Calculate annual leave accrual based on logged work (assumes 0.083 days of leave per work day)
    calculateAnnualLeaveAccrual() {
        const workDays = this.workItems.length;
        const leaveAccrued = workDays * 0.083; // Approx. 4 weeks leave per year

        return round(leaveAccrued, 2);
    }
}

export const basicEnterpriseAgreement = new EnterpriseAgreement({
    baseHourlyRate: DEFAULT_BASE_HOURLY_RATE,
    maxDailyHours: DEFAULT_MAX_DAILY_HOURS,
    maxWeeklyHours: DEFAULT_MAX_WEEKLY_HOURS,
    overtimeMultiplier: DEFAULT_OVERTIME_RATE
});

