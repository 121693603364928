import * as cronParser from "cron-parser";
import dayjs from "../dayjs";
import {secsSinceMidnightToDayjs} from "../model/timeFilter";

export const capitaliseWords = (input) => {
    input = input.toLowerCase();
    // Split the input string into an array of words
    let words = input.split(/\s+/);

    // Capitalize the first letter of each word
    words = words.map(word => {
        // Handle possessive nouns
        if (word.includes("'")) {
            const parts = word.split("'");
            return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join("'");
        }
        // Capitalize normal words
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words back into a single string
    return words.join(" ");
}

export const toHrsMinsSecs = (seconds, forceSecs, long, minMins = 5, showZero, roundUp = false) => {
    let negative = seconds < 0
    seconds = Math.abs(seconds)
    let hrs = Math.floor(seconds / 3600);
    let mins = Math.floor((seconds - (hrs * 3600)) / 60)
    let secs = seconds - (hrs * 3600) - (mins * 60)
    let result = ""
    if (hrs) {
        result += `${hrs.toFixed(0)}${long ? `hr${hrs > 1 ? 's' : ''}` : 'h'} `
    }

    if ( !forceSecs && roundUp && secs > 0) {
        mins++;
        secs = 0;
    }

    if (mins) {
        result += `${mins.toFixed(0)}${long ? `min${mins > 1 ? 's' : ''}` : 'm'} `
    }
    // if (hrs) {
    //     result += `${hrs}:`
    // }
    // if (mins) {
    //     result += `${mins} `
    // }
    if ((forceSecs || (hrs === 0 && mins < minMins)) && secs) {
        result += `${secs.toFixed(0)}${long ? `sec${secs > 1 ? 's' : ''}` : 's'} `
    }
    result = result.slice(0, result.length - 1)
    if (!result.length && showZero) {
        result = '0s'
    }
    return `${negative ? '- ' : ''}${result}`
}

export const roundToPrecision = (num, precision) => {
    let factor = Math.pow(10, precision);
    return Math.round(num * factor) / factor;
};

export const toKmMs = (metres, decimals = 1, minMetres = 1000) => {
    if (!Number.isFinite(metres) || Math.abs(metres) < 0) {
        return '< 50m'
    }
    if (Math.abs(metres) < minMetres) {
        return `${metres.toFixed(decimals)}m`;
    } else {
        return `${(metres / 1000.0).toFixed(decimals)}km`;
    }
}
export const to12HrTime = (seconds) => {
    return secsSinceMidnightToDayjs(seconds).format("LT")
}

export const toTime = (seconds = 0, forceSecs, window = -1) => {
    if (!Number.isFinite(seconds)) {
        return "--:--"
    }
    seconds = Math.floor(seconds)
    const hrs = String(toHrs(seconds)).padStart(2, '0');
    const mins = String(remainingMins(seconds)).padStart(2, '0');
    const secs = String(remainingSecs(seconds)).padStart(2, '0');
    let result = ""
    if (hrs) {
        result += `${hrs}:`
    }
    if (mins) {
        result += `${mins}`
    }
    if (forceSecs && secs) {
        result += `:${secs}`
    }
    if (window > -1) {
        result = `${result} - ${toTime(seconds + window, forceSecs)}`;
    }
    return result;
}

export const toDays = (seconds) => {
    return Math.floor(seconds / 3600.0 / 24.0);
}

export const toHrs = (seconds) => {
    return Math.floor(seconds / 3600.0)
}

export const toMins = (seconds) => {
    return Math.floor(seconds / 60.0)
}

export const remainingMins = (seconds) => {
    let hrs = toHrs(seconds)
    return Math.floor((seconds - (hrs * 3600.0)) / 60.0)
}

export const remainingSecs = (seconds) => {
    let hrs = toHrs(seconds)
    let mins = remainingMins(seconds)
    return seconds - (hrs * 3600) - (mins * 60)
}

export const getTripStartTime = (schedule) => {
    if (!schedule) {
        return
    }
    let interval = cronParser.parseExpression(schedule.cron).next()
    return String(interval.getHours()).padStart(2, '0') + String(interval.getMinutes()).padStart(2, '0')
}


// export const getStartTimeAsSecondsSinceMidnight = (route) => {
//     if (route.startHour === undefined || route.startMin === undefined) {
//         return 0
//     }
//     return route.startHour * 3600 + route.startMin * 60
// }

export const getStartTimeAsSecondsSinceMidnight = (tp) => {
    if (tp.arriveSecs !== undefined) {
        return tp.arriveSecs
    }
    if (!tp || tp.departHour === undefined || tp.departMin === undefined) {
        return -1;
    }
    return Math.round((parseInt(tp.departHour) * 3600 + parseInt(tp.departMin) * 60) / 60) * 60
}

export const getDepartTimeAsSecondsSinceMidnight = (tp) => {
    if (tp.departSecs !== undefined) {
        return tp.departSecs
    }
    return getStartTimeAsSecondsSinceMidnight(tp) + (tp.dwell || 0)
}

export const getSecondsSinceMidnightAsDayjs = (secsSinceMidnight) => {
    return dayjs().startOf('day').add(secsSinceMidnight, 's')
}

export const getStartTimeAsDayjs = (tp) => {
    const secsSinceMidnight = getStartTimeAsSecondsSinceMidnight(tp);
    if (secsSinceMidnight === undefined) {
        console.log('No start time')
        return false
    }
    return getSecondsSinceMidnightAsDayjs(secsSinceMidnight)
}

export const hexToRgb = hex => {
    hex = hex || "#000000";
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
        , (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16));
}

export const toBase64 = (file, stringOnly = false) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(stringOnly ? reader.result.split(',')[1] : reader.result);
        reader.onerror = (error) => reject(error);
    });

export const toUtf8String = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file, "utf8");
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const toDollarString = (value) => {
    if (value === undefined || value === null) {
        return '$ 00.00';
    }
    if ('string' === typeof value) {
        value = parseFloat(value);
    }
    return `$ ${value.toFixed(2)}`;
}

export const toTimeAgo = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (days > 30) {
        return 'Over a month ago';
    } else if (days > 14) {
        return 'Over 2 weeks ago';
    } else if (days > 7) {
        return 'Last week';
    } else if (days > 1) {
        return `${days} days ago`;
    } else if (days === 1) {
        return 'yesterday';
    } else if (hours === 1) {
        return '1 hour ago';
    } else if (hours > 1) {
        return `${hours} hours ago`;
    } else if (minutes === 1) {
        return '1 minute ago';
    } else if (minutes > 1) {
        return `${minutes} minutes ago`;
    } else {
        return 'Just now';
    }
}

export const isSameTimeExcludingSeconds = (t1, t2) => {
    const hours1 = Math.floor(t1 / 3600);
    const minutes1 = Math.floor((t1 % 3600) / 60);
    const hours2 = Math.floor(t2 / 3600);
    const minutes2 = Math.floor((t2 % 3600) / 60);

    return hours1 === hours2 && minutes1 === minutes2;
};
