import {flatten, uniqWith, values} from "lodash";
import {publicStopFilter} from "./routes-lib";
import dayjs from "../dayjs";

export const filterVisibleStops = (allStops, filteredRoutes, verifiedOnly) => {
    if (filteredRoutes?.some(r => r.stops?.length)) {
        const stopsForRoutes = flatten(filteredRoutes.map(r => r.getVisiblePublicStops()));
        return uniqWith(stopsForRoutes, (stop1, stop2) => stop1.stopId === stop2.stopId)
            .filter(s => !verifiedOnly || s.verified);
    }
    return values(allStops).filter(stop => publicStopFilter(stop) && (!verifiedOnly || stop.verified));
}


export const getRouteIdsForStopId = (routes, stopId) => {
    if (stopId && routes && routes.length) {
        return routes.filter(r => r.getPublicStops().some(s => s.stopId === stopId)).map(r => r.routeId);
    }
}

export const getFilterFromParams = (searchParams, routes, allStops) => {

    const params = new URLSearchParams(searchParams);
    let to = params.get('to');
    let from = params.get('from');
    const stopId = params.get('s');
    let routeIds = params.get('rs') || '';
    let regular = params.get('reg');
    let date = params.get('date');
    let search = params.get('search');
    let school = params.get('schl');
    let today = params.get('today');
    let truncate = params.get('t') || false;
    console.log('truncate:', truncate)
    const directions = params.get('dirs') || '';
    if (regular === school) {
        regular = true
        school = true
    }
    if (from && from.length) {
        const [fromLon, fromLat] = from.split(',').map(parseFloat);
        from = {center: [fromLon, fromLat], type: 'Feature', place_name: 'Start trip'}
    }
    if (to && to.length) {
        const [toLon, toLat] = to.split(',').map(parseFloat);
        to = {center: [toLon, toLat], type: 'Feature', place_name: 'End trip'}
    }
    let stops;
    if (stopId) {
        stops = [allStops[stopId]]
        stops.forEach(stop => {
            if (stop?.linkedStops?.length) {
                const {startBell, startBellWindow, endBell, endBellWindow} = stop
                stops = stops.concat(stop.linkedStops.map(ls => ({
                    ...allStops[ls.stopId],
                    startBell, startBellWindow, endBell, endBellWindow
                })));
            }
        })
        stops = stops.filter(s => !!s)
    }

    if (truncate) {
        routeIds = ''
    }
    if (routes && stops) {
        stops.forEach(stop => {
            const routeIdsForStop = getRouteIdsForStopId(routes, stop.stopId);
            if (Array.isArray(routeIdsForStop)) {
                routeIds += ',' + routeIdsForStop.join(',');
            }
        });
    }
    if (today && !date) {
        date = dayjs()
    } else if (date) {
        date = dayjs(date, "DD/MM/YYYY")
    }

    if (from || to || stopId || routeIds || regular || school || directions || truncate || date || search) {
        return {
            truncate,
            from,
            to,
            stopId,
            stops,
            date,
            search,
            stopIds: stops ? stops.map(s => s.stopId) : undefined,
            routeIds: routeIds.length && routeIds.split(',').filter(r => r.length),
            regular,
            school,
            directions: directions.length && directions.split(','),
            immutable: {
                directions: directions && !stopId && !routeIds.length && school === regular,
                type: regular !== school
            },
            isFilter: () => regular !== school || routeIds?.length || stopId || from || to || directions?.length || date || search
        };
    }
}

