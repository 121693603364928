import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Card, Col, Input, Row, Select, Splitter, Tooltip} from 'antd';
import {PlusOutlined, SyncOutlined} from '@ant-design/icons';
import WeeklyRosterTable from './WeeklyRosterTable';
import {toTime} from '../../libs/formatLib';
import './Rosters.css';
import {ReactComponent as BusPlus} from '../../assets/icons/BusPlus.svg';
import {ReactComponent as BusStat} from '../../assets/icons/BusStat.svg';
import {ReactComponent as BusQuote} from '../../assets/icons/BusQuote.svg';
import {ReactComponent as DownArrow} from '../../assets/icons/DownArrow.svg';
import {ReactComponent as Close} from '../../assets/icons/Close.svg';
import {Employee} from '../../model/hrm/employee';
import {DAYS_OF_WEEK, WeeklyRoster} from '../../model/roster';
import {WeeklyUnallocatedTable} from './WeeklyUnallocatedTable';
import {toCsv} from '../../libs/exportLib';
import fileDownload from 'js-file-download';
import dayjs from '../../dayjs';
import {capitalize, find, values} from 'lodash';
import WorkDiaryPrintModal from './WorkDiaryPrintModal';
import {FilterSwitches} from '../../containers/FilterTag';
import {useDebounce} from 'rooks';

const {Search} = Input;

const filterItems = {
    charter: {
        name: 'Charter',
        color: 'success',
        showFn: wi => wi?.duty?.charter,
    },
    fixed: {
        name: 'Fixed',
        color: 'success',
        showFn: wi => !wi?.duty?.charter,
    },
};

const WeeklyScenarioView = ({
                                // originalScenario,
                                scenario, setScenario,
                                previousRoster,
                                isLoading,
                                //showDashboards,
                                unallocatedRoster, operator,
                                employeeData, allVehicles, allSchedules, allStops, allRoutes,
                                printWorkItems, setPrintWorkItems,
                            }) => {

    const [showUnallocated, setShowUnallocated] = useState(true);
    // const [scenario, setScenario] = useState(originalScenario);

    const [selectedWorkItems, setSelectedWorkItems] = useState([]);

    const [driverHours, setDriverHours] = useState(0);
    const [wages, setWages] = useState(0);
    const [overtime, setOvertime] = useState(0);
    const [otpercentage, setOTPercentage] = useState(0);

    const unallocatedPanel = useRef();
    const rostersPanel = useRef();

    const [exportData, setExportData] = useState([]);

    const [showDashboards, setShowDashboards] = useState(true);

    const [employeeSelectData, setEmployeeSelectData] = useState({});
    const [selectedDrivers, setSelectedDrivers] = useState();
    const [selectedVehicles, setSelectedVehicles] = useState();
    const [filterBy, setFilterBy] = useState([]);
    const [filter, setFilter] = useState('');
    const setFilterDebounced = useDebounce(setFilter, 250);

    const filteredScenario = useMemo(() => {
        if (!employeeSelectData?.selected?.length && !selectedDrivers?.length && !selectedVehicles?.length && !filterBy?.length && !filter) {
            return scenario;
        }
        const txtFilter = filter?.length ? filter.toLowerCase() : null;

        const filteredScenario = scenario.clone();
        // filter employees
        filteredScenario.weeklyRosters.forEach(roster => {
            roster.workItems.forEach((workItemsForDay, day) => {
                roster.workItems[day] = workItemsForDay
                    .filter(workItem => {
                        const isEmployee = (!employeeSelectData?.selected?.length || find(employeeSelectData.selected, selected => selected && selected.extra.employeeId === workItem.employeeId));
                        const isDriver = !selectedDrivers?.length || selectedDrivers.includes(workItem.employeeId);
                        const isVehicle = !selectedVehicles?.length || selectedVehicles.includes(workItem.vehicleId);
                        const isFilter = (!filterBy?.length || filterBy.some(filter => filterItems[filter].showFn(workItem)));
                        const isTxtFilter = (!txtFilter || values(workItem).some(value => value && value.toString().toLowerCase().includes(txtFilter)) ||
                            DAYS_OF_WEEK.findIndex(dayOfWeek => dayOfWeek.includes(txtFilter)) === day
                        );
                        return isEmployee && isDriver && isVehicle && isFilter && isTxtFilter;
                    });
            });
        });
        filteredScenario.weeklyRosters = filteredScenario.weeklyRosters
            .filter(roster => roster.getTotalWorkItems());
        return filteredScenario;
    }, [scenario, selectedDrivers, selectedVehicles, employeeSelectData, filter, filterBy]);

    const saveRoster = useCallback(rosters => {
        rosters = Array.isArray(rosters) ? rosters : [rosters];
        setScenario(scenario => {
            rosters.filter(roster => !!roster).forEach(roster => {
                scenario.weeklyRosters = scenario.weeklyRosters.map(r => r.rosterId === roster.rosterId ? WeeklyRoster.from(roster) : r);
                scenario.updateUnallocatedRoster(unallocatedRoster);
                scenario.unallocatedRoster = scenario.unallocatedRoster.clone();
            });
            return scenario.clone();
        });
    }, [setScenario]);

    const newRoster = useCallback(() => {
        setScenario(scenario => {
            scenario.weeklyRosters = scenario.weeklyRosters.concat(WeeklyRoster.from({
                date: scenario.date,
                rosterName: 'ROS-' + (scenario.weeklyRosters.length + 1)
            }));
            return scenario.clone();
        });

        // setAllRosters(allRosters => allRosters ? {
        //     ...allRosters,
        //     [roster.rosterId]: roster
        // } : {[roster.rosterId]: roster});
    }, [setScenario]);

    // useEffect(() => {
    //     if (!scenario?.weeklyRosters) return;
    //     if (!scenario.weeklyRosters.filter(r => r.rosterId !== 'unallocated').length) {
    //         newRoster();
    //     }
    // }, [scenario?.weeklyRosters, newRoster]);

    useEffect(() => {
        if (filteredScenario?.unallocatedRoster?.isEmpty()) {
            setShowUnallocated(false);
        }
    }, [filteredScenario?.unallocatedRoster]);

    useEffect(() => {
        if (!scenario) return;

        let wages = 0, overtime = 0, hours = 0, exportData = [];
        filteredScenario.getAllEmployeesId().forEach(employeeId => {
            let employee = employeeData.getEmployee(employeeId);
            if (!employee) return;
            const workItems = filteredScenario.getWorkItemsForEmployee(employeeId);
            if (!employee.getWage) {
                employee = new Employee(employee);
            }
            const employeeWage = employee.getWage(workItems);
            hours += employeeWage.hours;
            overtime += employeeWage.overtime;
            wages += employeeWage.total;
            exportData.push({...employee, ...employeeWage});
        });

        hours = Number.isFinite(hours) ? hours : 0;
        overtime = Number.isFinite(overtime) ? overtime : 0;
        wages = Number.isFinite(wages) ? wages : 0;

        setExportData(exportData);
        setWages(wages);
        setDriverHours(toTime(hours * 3600, false));
        setOvertime(toTime(overtime * 3600, false));
        if (hours > 0) {
            setOTPercentage(Math.ceil(overtime / hours * 100));
        }
    }, [filteredScenario?.weeklyRosters, setWages, setDriverHours, setOvertime, setOTPercentage, employeeData, setExportData]);

    const setWorkItemOptions = useCallback((workItems) => {
        setScenario(scenario => {
            scenario.addOptionalWorkItems(workItems);
            scenario.setBaseData({employeeData, allVehicles});
            scenario.weeklyRosters = [...scenario.weeklyRosters];
            return scenario;
        });
    }, [setScenario, allVehicles, employeeData]);

    const onSelectWorkItems = useCallback(workItems => {
        if (!workItems) {
            setSelectedWorkItems([]);
            setWorkItemOptions(null);
            return;
        }
        setSelectedWorkItems(workItems);
        setWorkItemOptions(workItems);
    }, [setSelectedWorkItems, setWorkItemOptions]);


    const workDiaries = useMemo(() => {
        const workDiaries = [];
        filteredScenario.getAllEmployeesId().forEach(employeeId => {
            const employee = employeeData.getEmployee(employeeId);
            if (!employee) return;
            Array(7).fill().forEach((_, day) => {
                const workItemsForDay = filteredScenario.getWorkItemsForEmployeeForDay(employeeId, day);
                if (!workItemsForDay.length) return;
                const workDiary = {
                    name: employee.firstName + ' ' + employee.lastName,
                    day: capitalize(dayjs().weekday(day).format('dddd')),
                    workItems: workItemsForDay
                };
                workDiaries.push(workDiary);
            });

        });
        return workDiaries;
    }, [filteredScenario, employeeData]);

    const rosteredVehicles = useMemo(() => {
        return scenario.getAllVehicleIds().map(vehicleId => allVehicles[vehicleId]);
    }, [scenario, allVehicles]);

    const rosteredDrivers = useMemo(() => {
        return scenario.getAllEmployeesId().map(employeeId => employeeData.getEmployee(employeeId));
    }, [scenario, employeeData]);

    return (<div key={scenario.scenarioId}>
            <div className="expand-button-wrap">
                <Tooltip title={`${showDashboards ? 'Hide' : 'Show'} Dashboard`} mouseEnterDelay={0.9}>
                    <Button className={`icon-button ${showDashboards ? 'active' : ''}`}
                            onClick={() => {
                                setShowDashboards(!showDashboards);
                            }}><DownArrow/></Button>
                </Tooltip>
            </div>
            <Row key={scenario.scenarioId} gutter={[20, 20]}
                 className={`Dashboards mb-4 ${showDashboards ? 'show' : 'hide'}`}>
                <Col xs={24} lg={6} onClick={() => {
                    if (showUnallocated) {
                        onSelectWorkItems(null);
                    }
                    setShowUnallocated(!showUnallocated);
                }}>
                    <Card bordered={false}
                          className="card-main card-stats card-stats-flex">
                        <BusPlus/>
                        <div>
                            <h4>Unallocated</h4>
                            <div className="text-lg">{isLoading ?
                                <SyncOutlined
                                    spin/> : (filteredScenario?.unallocatedRoster?.getTotalWorkItems() || 0)}</div>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} lg={12}>
                    <Card bordered={false}
                          className="card-main card-stats card-stats-profit card-stats-flex">
                        <BusStat/>
                        <div>
                            <h4>Driver Hours</h4>
                            <div className="text-lg">{driverHours}</div>
                        </div>
                        <div>
                            <h4>Overtime</h4>
                            <div className="text-lg">{overtime}</div>
                        </div>
                        <div>
                            <h4>Overtime %</h4>
                            <div className="text-lg">{otpercentage}%</div>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} lg={6}>
                    <Card bordered={false}
                          className="card-main card-stats card-stats-flex">
                        <BusQuote/>
                        <div>
                            <h4>Total Wages</h4>
                            <div className="text-lg">${wages.toFixed(2)}</div>
                        </div>
                    </Card>
                </Col>
            </Row>

            <div
                className="d-flex align-items-center justify-content-between filter-options-main">
                <div className="d-flex align-items-center" style={{gap: '10px'}}>
                    <Button type="primary" className="icon-button" icon={<PlusOutlined/>}
                            onClick={newRoster}>New Roster</Button>
                    <Button type="primary"
                            className={`icon-button btn-filled btn-error ${showUnallocated ? 'active' : ''}`}
                            onClick={() => {
                                if (showUnallocated) {
                                    onSelectWorkItems(null);
                                }
                                setShowUnallocated(!showUnallocated);
                            }}><i>{isLoading ? <SyncOutlined
                        spin/> : (filteredScenario?.unallocatedRoster?.getTotalWorkItems() || 0)}</i> Unallocated</Button>

                    <Button type="primary" className={`icon-button`}
                            onClick={() => {
                                toCsv(exportData).then(csv => {
                                    fileDownload(csv, `Busable_Employee_Wages_${dayjs().format('YYYY-MM-DD_HH:mm')}.csv`, 'text/csv');
                                });
                            }}>Export</Button>
                </div>
                <div>
                    <div className="d-flex align-items-center">
                        {filter || filterBy || selectedVehicles?.length || selectedDrivers?.length ?
                            <Button onClick={() => {
                                setFilter('');
                                setFilterBy([]);
                                setSelectedVehicles([]);
                                setSelectedDrivers([]);
                            }} type="primary" className="icon-button btn-filled icon-10 btn-xs"><Close/> Clear
                                Filters</Button>
                            : <></>
                        }
                        <Select
                            className={'mr-2'}
                            style={{width: 150}}
                            showSearch
                            optionFilterProp="label"
                            placeholder="Filter by vehicle"
                            multiple={true}
                            value={selectedVehicles}
                            onChange={setSelectedVehicles}
                            options={rosteredVehicles.map(vehicle => ({
                                label: vehicle?.vehicleName || vehicle?.vehicleRego,
                                value: vehicle?.vehicleId
                            }))}/>
                        <Select
                            className={'mr-2'}
                            style={{width: 150}}
                            showSearch
                            optionFilterProp="label"
                            placeholder="Filter by driver"
                            multiple={true}
                            value={selectedDrivers}
                            onChange={setSelectedDrivers}
                            options={rosteredDrivers.map(driver => ({
                                label: driver?.firstName + ' ' + driver?.lastName,
                                value: driver?.employeeID
                            }))}/>

                        <div className="justify-content-end w-separator">
                            <Search
                                id="basic-addon1"
                                style={{
                                    width: 200,
                                }}
                                allowClear={true}
                                onChange={(e) => setFilterDebounced(e.target.value)}
                            />
                        </div>
                        <div className="filter-switches">
                            <FilterSwitches filterItems={filterItems} filterBy={filterBy}
                                            setFilterBy={setFilterBy}/>
                        </div>
                    </div>
                </div>
            </div>
            <WorkDiaryPrintModal key={'work-diary-print'}
                                 allRoutes={allRoutes}
                                 allStops={allStops}
                                 schedules={allSchedules}
                                 visible={printWorkItems}
                                 setVisible={setPrintWorkItems}
                                 workDiaries={workDiaries}
            />
            <div className="rosters-grid">
                <div className="rosters-grid-wrap">
                    <div className={'weekly-roster-table header-roster-table'}></div>
                    {showUnallocated ?
                        <Splitter layout="vertical" style={{height: showDashboards ? '60vh' : '70vh'}}>
                            <Splitter.Panel defaultSize={'30%'} min={'20%'} ref={unallocatedPanel}>
                                <WeeklyUnallocatedTable
                                    scenario={filteredScenario}
                                    selectedWorkItems={selectedWorkItems}
                                    setSelectedWorkItems={onSelectWorkItems}
                                    allStops={allStops}
                                    allRoutes={allRoutes}
                                    schedules={allSchedules}
                                    scroll={{y: unallocatedPanel.current?.clientHeight || 900}}
                                    employeeData={employeeData}
                                    setPrintWorkItems={setPrintWorkItems}
                                />
                            </Splitter.Panel>
                            <Splitter.Panel min={'20%'} ref={rostersPanel}>
                                <WeeklyRosterTable
                                    showUnallocated={showUnallocated}
                                    previousRoster={previousRoster}
                                    scenario={filteredScenario}
                                    setScenario={setScenario}
                                    saveRoster={saveRoster}
                                    newRoster={newRoster}
                                    employeeData={employeeData}
                                    allVehicles={allVehicles}
                                    allSchedules={allSchedules}
                                    selectedWorkItems={selectedWorkItems}
                                    setSelectedWorkItems={onSelectWorkItems}
                                    scroll={{y: rostersPanel.current?.clientHeight || 900}}
                                    allStops={allStops}
                                    allRoutes={allRoutes}
                                    schedules={allSchedules}
                                    operator={operator}
                                    hideHeader={true}
                                    setPrintWorkItems={setPrintWorkItems}
                                />
                            </Splitter.Panel>
                        </Splitter>
                        :
                        <WeeklyRosterTable
                            showUnallocated={showUnallocated}
                            previousRoster={previousRoster}
                            scenario={filteredScenario}
                            setScenario={setScenario}
                            saveRoster={saveRoster}
                            newRoster={newRoster}
                            employeeData={employeeData}
                            allVehicles={allVehicles}
                            allSchedules={allSchedules}
                            selectedWorkItems={selectedWorkItems}
                            setSelectedWorkItems={setSelectedWorkItems}
                            allStops={allStops}
                            allRoutes={allRoutes}
                            schedules={allSchedules}
                            operator={operator}
                            setPrintWorkItems={setPrintWorkItems}
                        />}
                </div>
            </div>
        </div>
    );
};

export default React.memo(WeeklyScenarioView);